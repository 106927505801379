//https://github.com/laraveller/react-authentication-with-breeze-api/blob/main/src/api/axios.js
import axios from "axios";
import { BACK_HOST, ZAM_HDR, SERVICE_HDR } from "../js/global";

export default axios.create({
	baseURL: BACK_HOST,//"http://back.fpanel.localhost:8000/",
	//withCredentials: true,
	headers: {
		'Accept': 'application/json',
		'Access-Control-Allow-Origin': '*',

		'Content-Type' : 'application/json',
		'Access-Control-Allow-Credentials':true,
		'ZamOnline_v2': true, // <- OLD - DELETE
		// Заголовок для ZamOnline_v2
		[`${SERVICE_HDR}`]: ZAM_HDR, // Динамическое свойство
	},
	withCredentials: true
}); 
