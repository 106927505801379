const KoszykSvg = ({ size = 50, color = 'black', className = '',id, onClick }) => {
	return (
		<div
			className={className} // Добавляем переданный класс
			style={{
				width: `${size}px`,
				height: `${size}px`,
			}}
			id={id} // Добавляем ID, если указан
			onClick={onClick} // Добавляем обработчик клика
		>
			<img
				src={`${process.env.PUBLIC_URL}/assets/images/koszyk.svg`}
				alt="Koszyk"
				style={{
					width: `${size}px`,
					height: `${size}px`,
					//filter: `invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0) contrast(0)`,
					//WebkitFilter: `invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0) contrast(0)`, // Safari fallback
					//fill: `${color}`
				}}
			/>
		</div>
	);
};

export default KoszykSvg;