import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavbarTop } from './Pages/Layouts/Navbar';
import { Footer } from './Pages/Layouts/Footer';
import HeaderRoutes from './Components/Routes';
import { AxiosProvider } from './Context/axios_req';
import useAuthContext from './Context/AuthContext';
import { ToastProvider } from './Context/ToastContext';
/* for translate */
import { useTranslation } from 'react-i18next';

import cookies from 'js-cookie';

import { BrowserRouter as Router } from 'react-router-dom'; 

/* Include header */
//import Header from './Components/Header';

/* Routes for my application */
//import HeaderRotes from './Components/Routes';

/* Include CSS */
import 'bootstrap/dist/css/bootstrap.min.css';
import "flag-icon-css/css/flag-icons.min.css";
import './App.css';
import './css/Buttons.css'

/* Include our functions */
import { capFrst } from './js/func';

import { langs } from './Components/Locale/Locales';
import { CartProvider } from './Context/CartContext';
import { CookiesApply } from './Pages/Layouts/CookieConcent';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { apiKeyK } from './js/global';

// const GlobeIcon = ({width=24, height=24}) => (
//   <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="currentColor" className="bi bi-globe" viewBox="0 0 16 16">
//   <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
// </svg>
// );

/* Vertical centered DIV */
export const DivCntr = (props) => {
	return  (
		<div 
			className={`d-flex align-items-center ${ props.addcls }`}
		>
			{props.children}
		</div>
	)
};

export const cntr_horiz = 'd-flex align-items-center';






/****************************************************** TEST *****************************************************/
function Repeat(props) {
	let items = [];
	for (let i = 0; i < props.numTimes; i++) {
		items.push(props.children(i));
	}
	return <div ff='df'>{items}</div>;
}

export function ListOfTenThings() {
	return (
		<Repeat numTimes={10}>
			{(index) => <div id={index} key={index}>Это элемент списка с ключом {index}</div>}
		</Repeat>
	);
}


/**
 * Random from 0 to max (for background images)
 * 
 * @param {integer} max - maximum of random digit
 * @returns 
 */
function getRandomInt(max) {
	return Math.floor(Math.random() * max);
}





/**
 * Styles for opacity image background
 */
const styles = {
	header: {
		// backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/bg/'+ (getRandomInt(20)+1) + '.jpg'})`,
		backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/bg/'+ (getRandomInt(4)+1) + '.jpg'})`,
		// backgroundImage: `url(${process.env.PUBLIC_URL + '/assets/images/bg/'+ (1) + '.jpg'})`,
		backgroundSize: 'cover',  // Изображение должно растягиваться, чтобы покрыть весь контейнер
		backgroundPosition: 'center',  // Центрирует изображение
		backgroundRepeat: 'no-repeat',  // Не повторяется
		backgroundAttachment: 'fixed',  // Для эффекта параллакса, можно удалить, если не нужен
		minHeight: '100vh',  // Минимальная высота — 100% высоты экрана
		height: '100%',  // Высота контейнера — 100% от доступной высоты
		width: '100%',  // Ширина контейнера — 100% ширины экрана
	},
	// Псевдоэлемент для размытия верхней половины изображения
	headerAfter: {
		content: '""',
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: '50%',  // Ограничиваем размытие верхней половиной
		background: 'transparent',  // Без цвета, только с эффектом размытия
		backdropFilter: 'blur(10px)',  // Эффект размытия на верхней части
		zIndex: 0,  // Размытие будет поверх фонового изображения, но ниже контента
	},
	content: {
		minHeight: '100vh',
		flex: 1,
		width: '100%',
		backgroundColor: "rgba(255, 255, 255, 0.7)",
		paddingBottom: "5px",
		//color: "black"
	},
};


/**
 * MAIN APP !
 */
const App = () => {
	return (
		<Router>
			<MainApp />
		</Router>
	);
};





/**
 * Main app in router
 * 
 */
const MainApp = () => {

	const location = useLocation(); // Получаем текущий путь

	// Функция для задания прозрачности в зависимости от пути
	const getContentBackgroundColor = () => {
		let opacity = '0.7'
		switch (location.pathname) {
			case '/':
				opacity = '0.1'; // Прозрачность для главной страницы
				break;
			case '/orders':
				opacity = '0.4' // Прозрачность для страницы "orders"
				break;
			case '/contacts':
				opacity = '0.6' // Прозрачность для страницы контактов
				break;
			default:
				opacity = '0.7'; // Прозрачность по умолчанию
				break;
		}
		return `rgba(255, 255, 255, ${opacity})`; // Прозрачность для текущей страницы
	};

	const currentLangCode = cookies.get('i18next') || 'en';
	const currentLang = langs.find(l => l.code === currentLangCode);
	const [isUserDataLoaded, setIsUserDataLoaded] = useState(false);

	const { t } = useTranslation();

	const { user_data, getUser_data } = useAuthContext();

	/* Change language */
	useEffect(() => {
		document.body.dir = currentLang.direction || 'ltr';
		document.title = capFrst(t('app_title'));
	}, [currentLang, t]);

	/* OnLoad */
	useEffect(() => {
		const GetUserCredent = async () => {
			await getUser_data();
			setIsUserDataLoaded(true);
		}

		if(!user_data?.User?.Alias){
			GetUserCredent();
		};
		//console.log('ComponentDidMount');
		//console.log("Lng:", (navigator.language.toLowerCase().split(/[_-]+/)[0] || navigator.userLanguage));
	}, []);



	return (
	// 	<GoogleReCaptchaProvider 
	// 		reCaptchaKey={apiKeyK}
	// 		container={{ // optional to render inside custom element
	// 			parameters: {
	// 				badge: 'bottomleft', // optional, default undefined
	// 				theme: 'dark', // optional, default undefined
	// 				visibility: 'hidden!important'
	// 			}
	// 		}}
	// 	>
			<div style={styles.header} className="p-0 m-0">
			{/* Псевдоэлемент с эффектом размытия только на верхней половине */}
			{/* <div style={styles.headerAfter}></div> */}
				{/* <div style={styles.content}> */}
				<div style={{ ...styles.content, backgroundColor: getContentBackgroundColor() }}>

				{/* <div style={{ ...styles.content, backgroundColor: getContentBackgroundColor() }}> */}
					{/* Cart content */}
					<CartProvider>
						{ /* ROTES!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!! */}
						{/* <Router> */}
							<AxiosProvider>
								<ToastProvider>
									{/* Navbar */}
									<NavbarTop />
									{/* Routes */}
									<HeaderRoutes id='headerroutes' isUserDataLoaded={isUserDataLoaded}  className="pb-2 m-0"/>
									{/* Footer */}
									<Footer />
								</ToastProvider>
							</AxiosProvider>
						{/* </Router> */}
					</CartProvider>
				</div>
			</div>
		// </GoogleReCaptchaProvider>
	);
}




export default App;