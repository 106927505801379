/* HOME  page */
import React from "react";
import parse from "html-react-parser";
import { Alert, Container, Row, Col, Stack, Button } from "react-bootstrap";
/* for translate */
import { useTranslation } from "react-i18next";

import useAuthContext from "../Context/AuthContext";

import { capFrst } from "../js/func";
import FrmLogin from "./Forms/frmLogin";

import { EnvelopeAtFill } from "react-bootstrap-icons";
import { IsGuestAllow } from "../js/firm_def_configs";
import { useNavigate } from "react-router-dom";

export const Home_o = (props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { firmInfo, user_data, IsLoggedUser } = useAuthContext();

	return (
		<>
			<Container>
				{/* <ReCapt3Token token={(v)=>SetToken(v || '')} /> */}
			</Container>

			<br />
			<Container className="mb-10">
				<Alert variant="primary" className="bg-gradient-primary shadow">
					<h2 className="text-center ">
						{capFrst(t("welcome_to_service"))} ({t("app_title")}):
					</h2>
					{(firmInfo?.Nazw || firmInfo?.Nazw2) && (
						<>
							<h4 className="text-center fst-italic">
								{firmInfo?.Nazw2 || firmInfo?.Nazw}
							</h4>
						</>
					)}

					<Alert
						variant="warning"
						className="bg-gradient-warning shadow d-flex align-items-center"
					>
						<Row className="justify-content-center">
							<Col className="p-2 d-flex text-wrap align-items-center">
								<img
									src={
										process.env.PUBLIC_URL +
										"/assets/images/" +
										(IsLoggedUser() ? "" : "no") +
										"login.svg"
									}
									style={{ maxHeight: "150px" }}
									className="align-middle img-fluid"
									alt=""
								/>
								&nbsp;
							</Col>
							<Col
								sm={"auto"}
								className="p-2 d-flex align-items-center text-sm-center"
							>
								<strong className="align-middle text-sm-center">
									{t("userhi")}:
								</strong>
							</Col>
						</Row>
					</Alert>

					{IsLoggedUser() ? (
						<>
							<table>
								<tbody>
									<tr>
										<td className="pe-2">
											<b>
												{user_data?.User?.UZYT ? t("usrname") : t("firmname")}:{" "}
											</b>
										</td>
										<td> {user_data?.User?.Nazw}</td>
									</tr>userhi
									{!user_data?.User?.UZYT && user_data?.User?.osoba && (
										<tr>
											<td className="pe-2">
												<b>{t("osoba")}:</b>{" "}
											</td>
											<td> {user_data?.User?.osoba}</td>
										</tr>
									)}
									<tr>
										<td className="pe-2">
											<b>{t("usremail")}:</b>{" "}
										</td>
										<td> {user_data?.User?.Mail}</td>
									</tr>
									{user_data?.User?.Address && (
										<tr>
											<td className="pe-2">
												<b>{t("usraddr")}:</b>{" "}
											</td>
											<td> {user_data?.User?.Address}</td>
										</tr>
									)}
									{!!user_data?.User?.NIP && (
										<tr>
											<td className="pe-2">
												<b>{t("usrnip")}:</b>{" "}
											</td>
											<td> {user_data?.User?.NIP}</td>
										</tr>
									)}
								</tbody>
							</table>
						</>
					) : (
						<>
							<Stack className="m-0 p-0" direction="horizontal" gap={2}>
								<div>
									{/* <span>{t('mustlogin')}.</span>
									<br /> */}
									{/* <strong className='text-danger'> {parse(t('passfromadmin', {mail: firmInfo?.mail ?? ""}))}</strong> */}
									<strong className="text-danger">
										{parse(t("passfromadminmail"))}
										{firmInfo?.mail && (
											<>
												<EnvelopeAtFill color="#3385ff" />
												{` `}
												<a href={`mailto:${firmInfo?.mail}`}>
													{firmInfo?.mail}
												</a>
											</>
										)}
										<Telefon info={firmInfo} />
									</strong>
								</div>

								{(IsLoggedUser() || IsGuestAllow()) && (
									<div className="mx-2 ms-auto">
										<Button
											className="ani-btn shadow"
											variant="warning"
											onClick={() => {
												navigate("/towary");
											}}
										>
											{t("form.buttons.oferta")}
											{` »`}
										</Button>
									</div>
								)}

								<div className="ms-auto">
									{/* If NOT LOGGED */}
									{!IsLoggedUser() && (
										<>
											<FrmLogin setFrmval={1} className="ani-btn" btns={true} />
										</>
									)}
								</div>
							</Stack>
						</>
					)}
				</Alert>
				<br />
			</Container>
			{/* If NOT LOGGED */}
			{!IsLoggedUser() && <OpenFormLogin {...props} sh={props.start} />}
		</>
	);
};



const Home = (props) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { firmInfo, user_data, IsLoggedUser } = useAuthContext();

	return (
		<>
			<Container fluid className="align-middle">
				<Row className="px-2 h-100 mb-10">
					<Col 
						md={6} 
						lg={7}
						xl={8}
						className=""
					>
						<Container
							fluid
							// className="py-4 text-wrap blur-background-text-main"
							className="py-4 text-wrap align-middle"
						>
							<p className="fw-bold display-4 text-left text-white text-uppercase " style={{ textShadow: "0px 4px rgba(0, 0, 0, 0.25)"}}>
								Twoje potrzeby,&nbsp;
								<br />
								Nasze rozwiązania
							</p>
						</Container>
					</Col>

					<Col
						md={6}
						lg={5}
						xl={4}
						className="rounded p-0 text-wrap   border-5 border-danger"
					>
						{/* Размытие фона */}
						<Container
							fluid
							className="blur-background-main-block p-2 py-5 align-middle"
							style={{ minHeight: `calc(100vh - ${150}px)` }} // Рассчитываем высоту с учетом меню
						>
							<h4 className="text-center pb-4 fw-bold text-primary-own">
								<span className="text-uppercase">
									{capFrst(t("welcome_to_service"))}
								</span>{" "}<br />
								({t("app_title")}):
							</h4>

							{(firmInfo?.Nazw || firmInfo?.Nazw2) && (
								<>
									{/* <p className="text-center font-extra-bold display-6 text-primary-own"> */}
									<h1 className="text-center font-extra-bold text-primary-own">
										{firmInfo?.Nazw2 || firmInfo?.Nazw}
									</h1>
								</>
							)}

							<br />

							<Container fluid className="text-center">
								<img
									src={
										process.env.PUBLIC_URL +
										"/assets/images/" +
										(IsLoggedUser() ? "" : "no") +
										"login.svg"
									}
									style={{ height: "150px" }}
									className="align-middle img-fluid"
									alt=""
								/>
							</Container>


							<br />
							<Container fluid className="text-center text-primary-own">
								{IsLoggedUser() ? (
									<>
										<Row className="text-center ">
											<Col sm={12} className="pb-2"> 
												<h4 className="fw-bold">
													{user_data?.User?.Nazw}
												</h4>
											</Col>
										</Row>
										<table>
											<tbody>
												{!user_data?.User?.UZYT && user_data?.User?.osoba && (
													<tr>
														<td className="pe-2 text-end">
															<h5>
																{t("osoba")}:{" "}
															</h5>
														</td>
														<td className="text-start">
															{user_data?.User?.osoba}
														</td>
													</tr>
												)}

												<tr>
													<td className="pe-2 text-end">
														<h5>
															{t("usremail")}:{" "} 
														</h5>
													</td>
													<td className="text-start">
														<h5>
															{user_data?.User?.Mail}
														</h5>
													</td>
												</tr>

												{user_data?.User?.Address && (
													<tr>
														<td className="pe-2 text-end">
															<h5>
																{t("usraddr")}:{" "}
															</h5>
														</td>
														<td className="text-start">
															<h5>
																{user_data?.User?.Address}
															</h5>
														</td>
													</tr>
												)}
												{!!user_data?.User?.NIP && (
													<tr>
														<td className="pe-2 text-end">
															<h5>
																{t("usrnip")}:{" "}
															</h5>
														</td>
														<td className="text-start">
															<h5>
																{user_data?.User?.NIP}
															</h5>
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</>
								) : (
									<>
										<Container fluid className="text-start fw-bold py-2">
											<h4 className="text-start fw-bold" >
												{parse(t("passfromadminmail"))}
											</h4>
											<Row className="pt-2">
												<Col className="p-0">
														<div className="h4 text-primary-own">
															{firmInfo?.mail && (
																<>
																	<EnvelopeAtFill />
																		{` `}
																	<a href={`mailto:${firmInfo?.mail}`} className="text-primary-own">
																		{firmInfo?.mail}
																	</a>
																	<br />
																</>
															)}
															<Telefon info={firmInfo} size={25} className="text-primary-own" />
														</div>
													<br />
												</Col>
												<Col sm={5}>
												{/* If NOT LOGGED */}
												{!IsLoggedUser() && (
													<>
														<FrmLogin setFrmval={1} className="ani-btn" btns={true} />
													</>
												)}
												</Col>
											</Row>
										</Container>

										<hr style={{ borderTop: "3px solid #003f62"}} />
										{(IsLoggedUser() || IsGuestAllow()) && (
											<div className="mx-2 pt-2 ms-auto">
												<Button
													className="ani-btn shadow"
													variant="warning"
													onClick={() => {
														navigate("/towary");
													}}
												>
													{t("form.buttons.oferta")}
													{` »`}
												</Button>
											</div>
										)}
									</>
								)}
							</Container>
						</Container>
					</Col>
				</Row>
			</Container>

			<Container>
				{/* <ReCapt3Token token={(v)=>SetToken(v || '')} /> */}
			</Container>

			{/* If NOT LOGGED */}
			{!IsLoggedUser() && <OpenFormLogin {...props} sh={props.start} />}
		</>
	);
};





const Telefon = ({ info, size = 15, className = '' }) => {
	return (
		<>
			{(info?.t1 || info?.t2 || info?.fax) && (
				<>
					<img
						src={`${process.env.PUBLIC_URL}/assets/images/icons/tel.png`}
						alt="phone"
						style={{
							width: `${size}px`,
							height: `${size}px`,
						}}
						className={className} // Добавляем переданный класс
					/>
					{` `}
					<a 
						href={`tel:${info?.t1 || info?.t2 || info?.fax}`}
						className={className} // Добавляем переданный класс
					>
						{info?.t1 || info?.t2 || info?.fax}
					</a>
				</>
			)}
		</>
	);
};


const OpenFormLogin = (props) => {
	return (
		<>
			{props?.start && (
				<Container className="invisible">
					<FrmLogin dbal={props?.dbal} email={props?.email} sh={props?.start} />
				</Container>
			)}
		</>
	);
};

export default Home;
