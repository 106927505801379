/* eslint-disable eqeqeq */
import React, { useState/*, Link*/ } from 'react';
import { Navbar, Container, Nav, NavDropdown, Dropdown, NavItem, Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { NavLink, Link, useSearchParams } from 'react-router-dom';
import useAuthContext from '../../Context/AuthContext';
import FrmLogin from '../Forms/frmLogin';	
import { capFrst, CapAll } from '../../js/func';
import LngSel, { LngSelCombo } from '../../Components/Locale/Locale_chooser';
import { useTranslation } from 'react-i18next';
import { FaShoppingCart } from 'react-icons/fa';

import useCardContext from '../../Context/CartContext';
import { BorderAll, PersonLinesFill } from 'react-bootstrap-icons';
import { IsGuestAllow } from '../../js/firm_def_configs';

//import Logo_firm from '../../Components/heart.ico';

const placement = "bottom";


// isActive menu item
const setActive = ({isActive}) => {
	return(
		'nav-link p-2 text-dark nav-link-round ' + (isActive ? 'act-link shadow' : 'ani-btn')
	);
}

// isActive menu item
const setActiveMenu = ({isActive}) => {
	return(
		'nav-link p-2 text-dark nav-item-round ' + (isActive ? 'act-nav-link ' : 'ani-btn')
	);
}



const NavbarTop = () => {

	const [expanded, setExpanded] = useState(false);
	const [cartOpen, setCartOpen] = useState(false);
	const { userCart } = useCardContext();

	const { firmInfo, user_data, IsLoggedUser } = useAuthContext();
	const { t } = useTranslation();

	const FirmName = capFrst(user_data?.Firm || t('shortname'));

	return (
		<>
			<Container fluid className='m-0 mb-3 py-1 bg-white bg-opacity-100 nav-line'>

				<Navbar collapseOnSelect expand="md" className='' 
					expanded={expanded}
				>
					<Container fluid>
						<Navbar.Brand href="/" alt="Main page" className='d-flex align-items-center'>
							<img 
								src={process.env.PUBLIC_URL + '/assets/images/logo.ico'}
								height="30" 
								className="d-inline-block align-top ani-btn" 
								alt="" 
							/>&nbsp;
							{
								FirmName?.length > 10 ?
									<RenderTooltip msg={FirmName} len={8} />
								:
									FirmName
							}
							
						</Navbar.Brand>
						<Navbar.Toggle aria-controls='responsive-navbar-nav' onClick={(e) => {setExpanded(expanded ? false : "expanded")}} />
						{/* <Navbar.Toggle aria-controls='responsive-navbar-nav' /> */}
						<Navbar.Collapse id='responsive-navbar-nav'>
							<Nav className='text-md-center me-auto d-flex align-items-md-center nav-container' onClick={(e) => {return (e.target.id !== 'nav-dropdown' && e.target.id !== 'dropdown-basic') ? setExpanded(false) : null}} >

								<>
									<NavLink to="/" className={setActiveMenu}>{CapAll(t('navbar.home'))}</NavLink>
								</>
								
								{(IsLoggedUser() || IsGuestAllow()) && 
									<NavLink to="/towary" className={setActiveMenu}>{CapAll(t('navbar.items'))}</NavLink>
								}

								{(IsLoggedUser()) && 
									<>
										<NavLink to="/orders" className={setActiveMenu}>{CapAll(t('navbar.orders'))}</NavLink>
									</>
								}
								<NavLink to="/contacts" className={setActiveMenu} >
									{firmInfo?.Nazw &&
										<span className="pe-1">
											<PersonLinesFill />
										</span>
									}
									{CapAll(t('navbar.contacts'))}
								</NavLink>
								{/* <NavLink to="/ide" className={setActiveMenu}>{capFrst(('Profile (IDE)'))}</NavLink> */}
								{/* Select language */}
								<LngSelCombo />
							</Nav>

							<Nav>
								{/* Shopping cart */}
								{!!userCart.length &&
									<NavLink to="/cart" className={setActiveMenu}>
										{expanded && CapAll(t('navbar.shopcart'))}
										<div className={`shop-cart-btn-hdr ${cartOpen && `active`}`} >
											<FaShoppingCart 
												//onClick={()=> setCartOpen(!cartOpen)} 
											/>
											<sup>
												<Badge bg="success" pill className='rounded-circle p-1 bg-opacity-75'>
													<small>
														{userCart.length || 0}
													</small>
												</Badge>
											</sup>
										</div>
									</NavLink>
								}

								&nbsp;&nbsp;&nbsp;
								{/* Login button and form */}
								<FrmLogin setFrmval={1} className='ani-btn' />
							</Nav>

						</Navbar.Collapse>
					</Container>
				</Navbar>
			</Container>


			{/* DELETED!!! */}
			<Container fluid className='rounded mt-2 d-none' >
				<div className='rounded bg-white'>
					<Navbar collapseOnSelect expand="md" className='rounded border border-info bg-info bg-opacity-25 border-opacity-25 shadow mb-3' 
						expanded={expanded}
					>
					{/* <Navbar fixed='bottom' collapseOnSelect expand="md" className='rounded border border-info bg-info bg-opacity-10 border-opacity-25 shadow mb-3' > */}
						<Container fluid>
							<Navbar.Brand href="/" alt="Main page" className='d-flex align-items-center'>
								<img 
									src={process.env.PUBLIC_URL + '/assets/images/logo.ico'}
									height="30" 
									className="d-inline-block align-top ani-btn" 
									alt="" 
								/>&nbsp;
								{
									FirmName?.length > 10 ?
										<RenderTooltip msg={FirmName} len={8} />
									:
										FirmName
								}
								
							</Navbar.Brand>
							<Navbar.Toggle aria-controls='responsive-navbar-nav' onClick={(e) => {setExpanded(expanded ? false : "expanded")}} />
							{/* <Navbar.Toggle aria-controls='responsive-navbar-nav' /> */}
							<Navbar.Collapse id='responsive-navbar-nav'>
								<Nav className='text-md-center me-auto d-flex align-items-md-center' onClick={(e) => {return (e.target.id !== 'nav-dropdown' && e.target.id !== 'dropdown-basic') ? setExpanded(false) : null}} >
									<NavLink to="/" className={setActive}>{capFrst(t('navbar.home'))}</NavLink>

									{(IsLoggedUser() || IsGuestAllow()) && 
										<NavLink to="/towary" className={setActive}>{capFrst(t('navbar.items'))}</NavLink>
									}

									{(IsLoggedUser()) && 
										<>
											<NavLink to="/orders" className={setActive}>{capFrst(t('navbar.orders'))}</NavLink>
										</>
									}
									<NavLink to="/contacts" className={setActive} >
										{firmInfo?.Nazw &&
											<PersonLinesFill />
										}
										{capFrst(t('navbar.contacts'))}
									</NavLink>
									{/* <NavLink to="/ide" className={setActive}>{capFrst(('Profile (IDE)'))}</NavLink> */}
									{/* Select language */}
									<LngSel />
								</Nav>
								<Nav>

									{/* Shopping cart */}
									{!!userCart.length &&
										<NavLink to="/cart" className={setActive}>
											{expanded && capFrst(t('navbar.shopcart'))}
											<div className={`shop-cart-btn-hdr ${cartOpen && `active`}`} >
												<FaShoppingCart 
													//onClick={()=> setCartOpen(!cartOpen)} 
												/>
												<sup>
													<Badge bg="danger" pill className='rounded-circle p-1'>
														<small> {userCart.length || 0}</small>
													</Badge>
												</sup>
											</div>
										</NavLink>
									}

									&nbsp;&nbsp;&nbsp;
									{/* Login button and form */}
									<FrmLogin setFrmval={1} className='ani-btn' />
								</Nav>

							</Navbar.Collapse>
						</Container>
					</Navbar>
				</div>
			</Container>
		</>
	)
}


	/**
	 * Render Tooltip on page
	 * @param {string} props 
	 * @returns Tooltop
	 */
	const RenderTooltip = (props) => (
		<OverlayTrigger
			placement={placement}
			overlay={
				<Tooltip id={`tooltip-${placement}`}>
					{props.msg}
				</Tooltip>
			}
		>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{props.msg.slice(0,(props.len || 14))}{'...'}
			</div>
		</OverlayTrigger>
	);

export { NavbarTop };