import { Button, Form, Modal, FloatingLabel, Row, Col, Alert, OverlayTrigger, Tooltip, Stack } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useMediaQuery } from 'react-responsive';

//import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
//import Tooltip from 'react-bootstrap/Tooltip';

import { PeopleFill, XCircleFill, Check2Circle, PersonPlusFill, PersonCheckFill } from "react-bootstrap-icons";
import useAuthContext from '../../Context/AuthContext';
import bcrypt from 'bcryptjs';



/* For translate */
import { useTranslation } from 'react-i18next';

//import nologin from '../../Components/nologin.png';
//import lgpng from '../../Components/login.png'
/* Include our functions */
import { isNOEmptyArr } from '../../js/func';
import { FrmRegister } from './frmRegister';



const FrmLogin = (props, ref) => {
	
	const { user_data } = useAuthContext();
	return (
		<div>
			{/* LogOFF or LogIN button and form */}
			{
				((user_data?.User?.Alias && <BtnLogOFF userdata={user_data} />) 
			|| 
				(<BtnLogON props={props} />))}
		</div>
	)

};





const BtnLogON = React.memo((props) => {
	props = props.props;

	const { firmInfo, firmConfig, GetFirmCfg, user_data, login, errors_login, setErrors_login } = useAuthContext();
	const {t} = useTranslation();

	const [form, setForm] = useState({
		dbal: (props?.dbal || "") ,
		email: (props?.email || "") ,
		log_in: (props.get?.log_in || ""),
		pass: "",
		rem_sw: ((props?.rem_sw || 1) ? props.rem_sw : 1),
		setFrmval : (props.setFrmval ? props.setFrmval : ""),
		setref : (props.setref ? props.setref : ""),
	});

	const [frmRegShow, setFrmRegShow] = useState(false);

	/////////////////https://www.youtube.com/watch?v=Jxfun6Jnt5Q///////////////////////////
	const {
		register,
		trigger,
		resetField,
		formState: {
			errors,
			isValid,
		},
		handleSubmit,
		reset,
		setValue,
		watch,
	} = useForm({
		mode: "all",
		defaultValues: {rem_sw: 1}
	});


	const onSubmit = (data) => {
		//console.log(JSON.stringify(data));
		//console.log("HashjPAss= " ,bcrypt.hashSync(data.password, bcrypt.genSaltSync()));
		data.pass = bcrypt.hashSync(data.password, bcrypt.genSaltSync());
		// console.log(data);
		// return;
		login(data);
	}

	const [frmLoginshow, setfrmLoginShow] = useState(props.sh || 0);

	const UsrLoginClose = (clrdbal=true) => {
		setfrmLoginShow(false);
		setErrors_login(null);
		reset();
		if (clrdbal)
			resetField('dbal');
		//frmLoginshow.reset();
	};

	const UsrLoginShow = () => {
		setfrmLoginShow(true);
	};

	/*if (props.sh) {
		UsrLoginShow();
		props.sh=false;
	}*/

	const Trig = async () =>{
		await trigger();
	}

	useEffect(()=>{
		if (frmLoginshow){
			setTimeout(() => {
				Trig();
			}, 100);
	
			const dbAlias = (props?.dbal || firmInfo?.IDFirmy || '');
			setValue("dbal", dbAlias);
			if (dbAlias)
				chgDBAlias();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[frmLoginshow])


	const chgDBAlias = async () => {
		const dbAlias = watch("dbal", ""); // Параметр - имя поля
		if (dbAlias) {
			// Запросить конфигурацию фирмы из БД
			GetFirmCfg(dbAlias)
		}
	}

	const [showLoginAfterReg, setShowLoginAfterReg] = useState(false);
	const RegisterShow = (shLginAftr) => {
		setShowLoginAfterReg(shLginAftr);
		UsrLoginClose(false);
		setFrmRegShow(true);
	}
	const RegisterClose = () => {
		setFrmRegShow(false);
		if (showLoginAfterReg)
			UsrLoginShow();
	}

	return (
		<div>
			{'' /* BUTTON FOR MODAL!!! */}
				{/*Modal new ({props.setFrmval}{props.setref || ''})*/}
				{!props.btns ? 
					<div 
						onClick={() => UsrLoginShow()} 
						className={`text-md-center text-sm-end text-end ani-btn ${(!user_data?.User?.Mail) ? "" : " d-none"}`} 
						role="button"
					>
						<img 
							src={process.env.PUBLIC_URL + '/assets/images/nologin.svg'} 
							data-toggle="tooltip" 
							data-placement="auto" 
							title="" 
							className="img-circle special-img inline-block" 
							height='40px' 
							data-original-title="Click here to login" 
							alt="" 
							style={{padding:0}}
						/> {' '}
						<strong>
							{t('form.buttons.login')} 
						</strong>
					</div>
					:
					<Button 
						variant="primary" 
						type='submit' 
						className='d-flex align-items-center ani-shad w-100'
						onClick={() => UsrLoginShow()} 
					>
						<PersonCheckFill />&nbsp;{t("form.buttons.login")}
					</Button>
				}
				{isNOEmptyArr(firmConfig?.Nazw3) && firmConfig?.Nazw3[3] === 'T' && props.btns && 
					<> 
						<div className='p-1 m-0'>
						</div>
						<BtnRegister showreg={() => RegisterShow(false)} />
					</>
				}

			{'' /* MODAL!!! */}
			<Modal 
				show={frmLoginshow} 
				onHide={() => {UsrLoginClose(false)}} 
				/*{...props} */
				backdrop="static" 
				//centered 
			>

				<Modal.Header closeButton className='alert bg-info bg-gradient bg-opacity-25' style={{padding:10}}>
					<strong className='d-flex align-items-center'>
						<PeopleFill /> &nbsp;
						{t('form.login.title')} {props.FrmHead}
					</strong>
				</Modal.Header>

				<Form onSubmit={handleSubmit(onSubmit)}>
					<Modal.Body>

						{/* AliasDB */}
						<Row className="mb-3">
							<Col sm={12} md={12}>
								<FloatingLabel controlId="frmLogin.labelAliasDB" label={t('form.login.alias.text')+":"}>
									<Form.Control
										className={(errors?.dbal && "is-invalid") || "is-valid"}
										type='input'
										size="lg" 
										{...register("dbal", {
											required: {
												value: true,
												message: t('form.login.errors.req'),
											},
											minLength: {
												value: 3,
												message: t('form.login.errors.min', {min_nr: 3}),
											},
										})}
										//defaultValue={form.dbal}
										onBlur={chgDBAlias} // Добавляем обработчик потери фокуса
									/>
									<Form.Text className={errors?.dbal ? "invalid-feedback" : "text-muted"}>
										<small>
											{(errors?.dbal && (errors?.dbal?.message || "Error!")) || t('form.login.alias.text-label')}
										</small>
									</Form.Text>
								</FloatingLabel>
							</Col>
						</Row>

						<Row className="mb-3">
							{/* Email */}
							<Col sm={12} md={6}>
								<FloatingLabel controlId="frmLogin.labelemail" label={t('form.login.email.text')+":"}>
									<Form.Control
										className={(errors?.email && "is-invalid") || "is-valid"}
										type='email'
										{...register("email", {
											required: {
												value: true,
												message: t('form.login.errors.req'),
											},
											minLength: {
												value: 3,
												message: t('form.login.errors.min', {min_nr: 3}),
											},
											pattern: {
												value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
												message: t('form.login.errors.email'),//"invalid email address"
											},
										})}
										defaultValue={form.email}
									/>
									<Form.Text className={errors?.email ? "invalid-feedback" : "text-muted"}>
										<small>
											{(errors?.email && (errors?.email?.message || "Error!")) || t('form.login.email.text-label')}
										</small>
									</Form.Text>
								</FloatingLabel>
							</Col>

							{/* Password */}
							<Col sm={12} md={6}>
								<FloatingLabel controlId="frmLogin.labelpassword" label={t('form.login.password.text')+":"}>
									<Form.Control
										className={(errors?.password && "is-invalid") || "is-valid"}
										type='password' 
										autoComplete='on'
										{...register("password", {
											required: {
												value: true,
												message: t('form.login.errors.req'),
											},
											minLength: {
												value: 1,
												message: t('form.login.errors.min', {min_nr: 1}),
											},
										})}
										autoFocus={form.dbal && form.email}

									/>
									<Form.Text className={errors?.password ? "invalid-feedback" : "text-muted"}>
										<small>
											{(errors?.password && (errors?.password?.message || "Error!")) || t('form.login.password.text-label')}
										</small>
									</Form.Text>
								</FloatingLabel>
							</Col>
						</Row>

						{/* Remember me */}
						<Row className="mb-3">
							<Col>
								<Form.Group controlId='frmLogin.formCheckbox' >
									<Form.Check 
										type='switch' 
										label={t('form.login.remember.text')} 
										id="rem_sw" 
										name="rem_sw" 
										{
											...register("rem_sw", {
												//disabled: true
											})
										} 
										>
									</Form.Check>
								</Form.Group>
							</Col>
						</Row>

					<Row className={`round ${(errors_login?.message) ? "" : " d-none"}`} >
						<Col>
							<Alert variant="danger" className='shadow round'>
								{errors_login?.message || user_data?.User?.UserName}
							</Alert>
						</Col>
					</Row>

					</Modal.Body>

					<Modal.Footer>
						<Row className="mb-2 w-100">
							<Stack
								className='m-0 p-0'
								direction="horizontal" 
								gap={3}
							>
								<div>
									<Button 
										variant="secondary" 
										onClick={() => UsrLoginClose(false)} 
										className='d-flex align-items-center ani-shad'
									>
										<XCircleFill />&nbsp;{t("form.buttons.close")}
									</Button>
								</div>

								<div className='ms-auto'>
{/* //{isNOEmptyArr(firmConfig?.Nazw3) && firmConfig?.Nazw3[3] === 'T' && false && */}
									{isNOEmptyArr(firmConfig?.Nazw3) && firmConfig?.Nazw3[3] === 'T' &&
										<BtnRegister showreg={() => RegisterShow(true)} />
									}
								</div>
								<div className=''>
									<Button 
										variant="primary" 
										type='submit' 
										className='d-flex align-items-center ani-shad ' 
										disabled={!isValid}
									>
										<PersonCheckFill />&nbsp;{t("form.buttons.login")}
									</Button>
								</div>
							</Stack>
						</Row>
{/*					<Row className="mb-2 w-100">
							<Col className='text-start justify-content-start p-0'>
								<Button variant="secondary" onClick={UsrLoginClose} className='d-flex align-items-center ani-shad'>
									<XCircleFill />&nbsp;{t("form.buttons.close")}
								</Button>
							</Col>
							<Col className='text-end justify-content-end p-0 d-flex align-items-center'>
								<Button variant="primary" type='submit' className='d-flex align-items-center ani-shad ' disabled={!isValid}>
									<Check2Circle />&nbsp;{t("form.buttons.login")}
								</Button>
							</Col>
						</Row> */}
					</Modal.Footer>
				</Form>
			
			</Modal>

			{isNOEmptyArr(firmConfig?.Nazw3) && firmConfig?.Nazw3[3] === 'T' &&
				<FrmRegister FrmShow={frmRegShow} dbalias={firmInfo.IDFirmy} e_mail={props?.email} FrmClose={(v) => {RegisterClose(v)}} />
			}
		</div>
	);
});


const BtnRegister = React.memo(({showreg}) =>{
	const {t} = useTranslation();
	return(
		<>
			<Button 
				variant='success'
				className='d-flex align-items-center ani-shad bg-opacity-25 w-100'
				onClick={showreg}
				//disabled={release}
			>
				<PersonPlusFill />&nbsp;{t('form.buttons.register')}
			</Button>
		</>
	)
})




/**
 * LogOFF Button and FORM
 * 
 */
const BtnLogOFF =  React.memo((props) => {

	const {t} = useTranslation();
	const { logout } = useAuthContext();

	const [frmshow, setFrmShow] = useState(0);

	const showForm = () => {
		setFrmShow(true);
	};

	const closeForm = () => {
		setFrmShow(false);
	}

	const submitForm = (e) => {
		e.preventDefault();
		logout();
	}

	const UserName = props.userdata?.User?.Alias;

	const renderTooltip = (props) => (
		<Tooltip id="button-tooltip" {...props}>
			{UserName}
		</Tooltip>
	);

	const isSmallScreen = useMediaQuery({ query: '(max-width: 576px)' });

	return(
		<div>
			{'' /* BUTTON LOGOFF */}
			
			<OverlayTrigger
				placement="bottom"
				delay={{ show: 250, hide: 400 }}
				overlay={renderTooltip}
			>
				<div 
					onClick={() => showForm()} 
					className={`text-md-center text-sm-end text-end ani-btn`} 
					role="button" 
				>
					<img 
						src={process.env.PUBLIC_URL + '/assets/images/login.svg'} 
						data-toggle="tooltip" 
						data-placement="auto" 
						className="img-circle special-img inline-block" 
						height='40px' 
						data-original-title={UserName}
						alt={UserName} 
						style={{padding:0}}
					/> {' '}
					<strong>
						{UserName.slice(0,14)}{UserName.length > 15 ? '...' : ''}
					</strong>
				</div>
			</OverlayTrigger>

			<Modal 
				show={frmshow} 
				onHide={() => {showForm(false)}} 
				backdrop="static" 
				centered={isSmallScreen}
			>
				<Form onSubmit={submitForm}>
						<Alert variant='warning' className='round shadow text-center m-0'>
							<h2 style={{display: "inline"}} className='mb-2'>&nbsp;{t('form.logoff.text')}</h2>

							<Stack direction="horizontal" gap={2} className="w-100 pt-4">
								<div>
									<Button variant="secondary" onClick={() => closeForm()} className='d-flex align-items-center ani-shad'>
										<XCircleFill />&nbsp;{t("form.buttons.close")}
									</Button>
								</div>
								<div className="ms-auto">
									<Button variant="primary" type='submit' className='d-flex align-items-center ani-shad' >
										<Check2Circle />&nbsp;{t("form.buttons.logoff")}
									</Button>
								</div>
							</Stack>
						</Alert>
				</Form>
			</Modal>
		</div>
	)

});




export default FrmLogin;