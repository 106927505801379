import { useEffect, useState, useRef } from "react";
import '../../css/Items.css';
import { Alert, Badge, Col, Container, Image, OverlayTrigger, Row, Spinner, Table, Tooltip, Form, Button } from "react-bootstrap";
import { PaginationControl } from 'react-bootstrap-pagination-control';

import useAxiosContext from "../../Context/axios_req";
import useCardContext from "../../Context/CartContext";

import { ItmDtl } from "./Towar";

import { FaShoppingCart } from "react-icons/fa";
import KoszykSvg from "./Koszyk";
import { CartPlusFill, SortAlphaDown, SortAlphaDownAlt } from "react-bootstrap-icons";

/* For translate */
import { useTranslation } from 'react-i18next';
import { CartSmallItem } from "../CartSmall";
import { capFrst, isEmptyArr, isNOEmptyArr, IsPLlng, isReq_data_Obj } from "../../js/func";
import { useParams } from "react-router-dom";
import { custID } from "../../js/global";
import useAuthContext from "../../Context/AuthContext";


export const TowList = () => {

	const { param, paramval } = useParams();
	const { IsLoggedUser } = useAuthContext();
	const pllng = IsPLlng();

	const class_sort = (col) => {
		return `text-start vcentr cpointer` + (sort.col === col ? ` bg-primary bg-gradient bg-opacity-10 rounded` : ``)
	}

	const url = "zam/towary/";
	const {t} = useTranslation();

	const [init, setInit] = useState(1);
	const [config, setConfig] = useState({});
	const [sort, setSort] = useState({
		col: '',
		dir: 'asc',
		ico: ''
	});

	useEffect(() =>{
		if (config)
			SetUserConfig(config)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [config])

	//const Screen = getScreenSize();

	const [req, setReq] = useState({req:false, to:''});

	// For items and pages
	const [tows, setTows] = useState({
		warehouse: "",
		itmgroup: "",
		itmprod: "",
		sort: "",
		search: "",
		page: 0, 
		perpage: 0, 
		total: 0,
		items: []
	});

	/* Open cart of added itemsor not */
	const [cartOpen, setCartOpen] = useState(false);

	const [itm, setItm] = useState({ID: "", kod:"", item:{}});
	const [showDtl, setShowDtl] = useState(false);
	const updateShowDtl = (DtlState) => {
		setShowDtl(DtlState);
	};
	useEffect(() => {
		if (!showDtl)
			setItm({ID: "", kod: "", item:{}});
	}, [showDtl])


	const { userCart, SetUserConfig } = useCardContext();
	const { req_data, Get_data } = useAxiosContext();


	/* Add item to User cart */
	const [usrCart, setUsrCart] = useState([]);
	const addToOrder = (item) => {
		setUsrCart([
			...usrCart,
			item
		]);
	}




	// Reload items if something is changed
	useEffect(() => {
		const GetPageTows = async () =>{
			setReq({req:true, to:'tows_pg'})
			await Get_data(url, 
				{
					page: (tows.page ? tows.page : 1),
					itmgroup: (tows.itmgroup ? tows.itmgroup : ""),
					itmprod: (tows.itmprod ? tows.itmprod : ""),
					sort: (tows.sort ? tows.sort : ""),
					search: (tows.search ? tows.search : ""),
					warehouse : (tows.warehouse ? tows.warehouse : ""),
					init: init
					//perpage : (perpage ? perpage : null)
					//page: 4
				});
			//setTowsList([]);
		}
		GetPageTows();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tows.page, tows.itmgroup, tows.itmprod, tows.search, tows.sort, tows.warehouse]);





	useEffect(() => {
		const GetItm = async (params) => {
			setReq({req:true, to:'get_Itm'})
			await Get_data(url ,//+ '/' + ID, 
				params
			/*{
				id: ID
			}*/);
		}

		if (itm?.ID !== "" || itm?.kod !== "") {
			GetItm(itm?.ID !== "" ? {id: itm.ID} : {kod: itm.kod})
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itm.ID, itm.kod]);
	


	// When data form request is coming
	useEffect(() => {
		let getdata = isReq_data_Obj(req_data)
		getdata = (((req.to === 'tows_pg' || req.to === 'get_Itm')) && req_data > 0) || getdata;

		if (getdata && req.req){
			setReq({req:false, to:''})

			if (req.to === 'tows_pg'){
				//SetTowsList(req_data);
				setTows((prev) => ({
					...prev,
					perpage: req_data.items?.per_page,
					total: req_data.items?.total,
					items: req_data.items?.data
				}));
				setItm({
					ID: (param?.toUpperCase() === "ID" && !!paramval) ? paramval : "", 
					kod: (param?.toUpperCase() === "KOD" && !!paramval) ? paramval : "", 
					item: {}
				});
				if (init){
					setConfig(req_data?.config)
					setInit(0);
				}
			}
			else if (req.to === 'get_Itm'){
				setItm({
					...itm,
					item: req_data
				})
				// Show modal
				setShowDtl(true);
			}
			else if (req.to === 'upd'){
				console.log(req.to);
				/*setUser(req.tmpdata);
				ADDToast('Dane zostały pomyślnie zapisane','success');
				setUserlist(req_data);*/
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [req_data])



	/**
	 * Change sort column and order
	 * @param {event} e 
	 */
	const ChgSort = (e) => {
		const col = (e.currentTarget.getAttribute('ids'))
		const dir = ((sort.col === col && sort.dir === 'asc') ? 'desc' : 'asc');

		setSort((prev) => ({
			...prev,
			col: col,
			dir: dir,
			ico: dir === "asc" ? <SortAlphaDown color="royalblue" title={dir} size={18} /> : <SortAlphaDownAlt color="royalblue" title={dir}  size={18}/>
			//ico: ` ` && dir === "asc" ? <SortDownAlt color="royalblue" title={dir}/> : <SortDown color="royalblue" title={dir}/>
		}));
	}

	useEffect(() => {
		if (sort.col){
			setTows(prevState => ({
				...prevState,
				sort: `${sort.col}|${sort.dir}`,
			}));
		}
	}, [sort])




	const [selectAddCls, setSelectAddCls] = useState({
		grp: 0,
		prod: 0,
		warehs: 0
	})

	// Change items groups or producers for display
	const handleSelectChange = (event) => {
		const val = event.target.value;
		const param = event.currentTarget.getAttribute('sortparam');
		
		setSelectAddCls({
			...selectAddCls,
			[param]: event.target.selectedIndex ? 'select_selected' : ""
		})

		setTows({
			...tows,
			page: 1,
			[param === 'grp' ? 
				'itmgroup' : 
				param === 'prod' ? 
					'itmprod' : 
					param === 'warehs' ? 
						'warehouse' :
						'UNKNOWN'
			]: val,
		})

		/*if (param === 'grp'){
			setTows({
				...tows,
				page: 1,
				itmgroup: val,
			})
		}
		else if (param === 'prod'){
			setTows({
				...tows,
				page: 1,
				itmprod: val,
			})
		}
		else if (param === 'wareh'){
			setTows({
				...tows,
				page: 1,
				warehouse: val,
			})
		}*/
	};

	
	// SEARCH !!!!!!!!!!!!
	const [searchTerm, setSearchTerm] = useState('');
	const timerRef = useRef(null);  
	const isFirstRender = useRef(true);
	const [searchFiltr, setSearchFiltr] = useState('%');

	/* Изменение параметров поиска */
	useEffect(() => {
		if (isFirstRender.current) {
			// Пропускаем выполнение useEffect при первоначальной загрузке компонента
			isFirstRender.current = false;
			return;
		}

		if (searchTerm) {
			timerRef.current = setTimeout(() => {
				fSearch(searchTerm);
			}, 1500);
		}
		else {
			fSearch(searchTerm);
		}

		return () => {
			clearTimeout(timerRef.current);
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchTerm]);

	const handleInputChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const handleKeyUp = (event) => {
		if (event.key === 'Enter') {
			clearTimeout(timerRef.current);
			fSearch(searchTerm);
		}
	};

	// When changed search filter
	const SearchFiltrChg = (event) => {
		setSearchFiltr(event.target.value);
	}
	useEffect(() => {
		if (searchTerm)
			fSearch(searchTerm);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[searchFiltr])


	//Function of search
	const fSearch = (term) => {
		if(term){
			term = term + '%';
			switch (searchFiltr) {
				case '%':
					term = '%' + term;
					break;
				case '_':
					term = "%" + term.replaceAll(' ', '_');
					break;
				default:
					break;
			}
		}
		setTows({
			...tows,
			page: 1,
			search: term,
		})
	};


	
	/**
	 * On double click on item
	 * @param {string} ID - Id of item
	 */
	const DblClkTow = async (ID) => {
		setItm({
			...itm,
			ID: ID
		});
	}

	return (
		<>
			<ItmDtl item={itm.item} config={config} showDtl={showDtl} updateShowDtl={updateShowDtl} addToOrder={addToOrder} />

			<Container fluid>

				{(tows?.items?.length || tows?.perpage) && (config) ? 
					<>
						<Container fluid>

							{/* Header of page items */}
							<Alert variant={'success'} className='p-2 bg-opacity-25 shadow text-center'>
								<Row>
									{/* Items header */}
									<Col className="text-sm-center text-md-end m-1">
										<h3 className='my-2'>
											<strong>
												<mark className='rounded-3 shadow'>
													{t('zam.list.hdr')}
												</mark>
											</strong>
											&nbsp;&nbsp;&nbsp;&nbsp;
										</h3>
									</Col>
									{/* Items search */}
									<Col sm={12} md='auto' className='my-2'>
										<Row>
											<Col md='auto' className="text-wrap p-2" >
												<b>{t('zam.search_lbl')}:</b>
											</Col>
											<Col md='auto'>
												<Form.Control
													autoFocus
													id="searchfld"
													type="search"
													htmlSize="25"
													autoComplete='on'
													className='live-search-field shadow'
													placeholder={t('zam.search_here')}
													value={searchTerm}
													onChange={handleInputChange}
													onKeyUp={handleKeyUp}
												/>
											</Col>
											<Col md={'auto'} >
												<Form.Select 
													aria-label="Select direction"
													id="srchdir"
													onChange={SearchFiltrChg}
													className="shadow"
													defaultValue={searchFiltr}
												>
													<option value={"%"} key={"%"} >{t('zam.srch_crit.all')}</option>
													<option value={""} key={""} >{t('zam.srch_crit.start')}</option>
													<option value={"_"} key={"_"} >{t('zam.srch_crit.phrase')}</option>
												</Form.Select>
											</Col>
										</Row>
									</Col>
								</Row>
							</Alert>


							<Row className="mb-md-2 mb-sm-3">
								{/* Items groups */}
								<Col sm={12} md={3} xl={2} className="m-0 mb-3">
									<div>
										{/* <Alert variant='success' className="bg-opacity-25" style={{backgroundColor: "#37BC9B"}}> */}
										<Alert variant='primary' className='p-2 bg-opacity-10 shadow'>
											{/* Warehouses */}
											{!isEmptyArr(config?.warehs) && config?.warehs?.length > 1 && (
												<>
													<b>{t('zam.warh')}:</b>
													<Form.Select 
														id="zam-wareh"
														size="sm"
														aria-label="Select group" 
														onChange={handleSelectChange}
														className={`shadow ${selectAddCls.warehs}`}
														sortparam="warehs"
													>
														<option value="" key={-1}>{t('zam.all_warh')}</option>
														{config?.warehs.map((v,i) => (
															((v !== "") &&
																<option value={v} key={v+i}>{
																	v.trim()
																} </option>
															)
														))}
													</Form.Select>
												</>
											)}
											{/* Group of items */}
											{!isEmptyArr(config?.tow_groups) && config?.Nazw3[5] === 'T' && (
												<>
													<b>{t('zam.groups')}:</b>
													<Form.Select 
														id="zam_grp"
														size="sm"
														aria-label="Select group" 
														onChange={handleSelectChange}
														className={`shadow ${selectAddCls.grp}`}
														sortparam="grp"
														//defaultValue={usrid || -1}
													>
														<option value="" key={-1} /*disabled={true}*/>{t('zam.all_groups')}</option>
														{config?.tow_groups.map((v,i) => (
															((v !== "") &&
																<option value={v.Nazw} key={i + v.Nazw} /*disabled={!v.all_wizyts_count}*/>{
																	// Group description
																		config?.Ceny?.jez?.toUpperCase() === 'PL' ?
																		v.Nazw?.trim()
																	:
																		// If v.Nazw3 is null or empty
																		(v.Nazw3 === null || v.Nazw3.trim() === "") ?
																			v.Nazw
																		:
																			v.Nazw3.trim()
																} </option>
															)
														))}
													</Form.Select>
												</>
											)}

											{/* Items producers */}
											{isNOEmptyArr(config?.tow_prods) && config?.Nazw3[4] === 'T' && (
												<>
													<br />

													<b>{t('zam.producers')}:</b>
													<Form.Select 
														id="zam_prod"
														size="sm"
														aria-label="Select producer" 
														onChange={handleSelectChange}
														sortparam="prod"
														className={`shadow ${selectAddCls.prod}`}
														//defaultValue={usrid || -1}
													>
														<option value="" key={-1} /*disabled={true}*/>{t('zam.all_producers')}</option>
														{config?.tow_prods?.map((v,i) => (
															(v !== "" ?
																<option value={v} key={v+i} /*disabled={!v.all_wizyts_count}*/>{v}</option>
															:
																""
															)
														))}
													</Form.Select>
												</>
											)}
											
											{((isNOEmptyArr(config?.tow_groups) && config?.Nazw3[5] === 'T') || 
												(isNOEmptyArr(config?.tow_prods) && config?.Nazw3[4] === 'T') ||
												isNOEmptyArr(config?.warehs)) &&
												<hr />
											}
											<b>{t('zam.price')}: <u>{t('zam.' + config?.Ceny?.NB)}</u></b> <br />
											<b>{t('zam.wal')}: <u>{config?.Ceny?.wal}</u></b>
											<hr />

											{/* Show Cart for current user */}
											{(userCart.length > 0) && (
												<>
													&nbsp;<FaShoppingCart
													onClick={()=> setCartOpen(!cartOpen)} 
														className={`shop-cart-btn mx-2 ${cartOpen && `active`}`}
													/>
													{cartOpen && (
														<SmallCart items={userCart} config={config} />
													)}
												</>
											)}

											{/* Add new item to order BUTTON */
											
											IsLoggedUser() &&
											<Row>
												<Col className="d-flex justify-content-center">
												<Button 
													variant="warning"
													className="ani-shad btn-ico align-content-center bg-gradient"
													onClick={() => DblClkTow(custID)}
													// disabled
												>
													{/* <FileEarmarkPlus />&nbsp;Od ręki */}
													<span>
														<KoszykSvg 
															size={25} 
															className="ani-btn rounded calias"
														/>
													</span>&nbsp;
													<span className="text-uppercase fw-bold">{` `}{capFrst(t('zam.frmItem.btns.add'))}</span>
												</Button>
												</Col>
											</Row>
											}
										</Alert>
									</div>
								</Col>


								{/* Items list */}
								<Col sm={12} md={9} xl={10} className="m-0 mb-3">
									{tows.items.length ?
										<Table striped hover responsive style={{backgroundColor:"white"}} className="rounded-3 shadow table-condensed">
											<thead className="table-active table-header-condensed" /*onClick={e => sort_arr(e.target)}*/>
												<tr className="cdefault">
													<th className="text-center vcentr" ids="Photo">{t('zam.list.zdj')}</th> 
													{config?.Nazw3[9] === 'T' ? 
														<th 
															className={class_sort('Kod')}
															ids="Kod" 
															onClick={ChgSort}
														>
															{t('zam.list.code')}{` `}
															{sort.col === 'Kod' && sort.ico}
														</th> 
													:
														""
													}
													{config?.Nazw3[10] === 'T' ?
														<th 
															className={class_sort('Kat')}
															ids="Kat"
															onClick={ChgSort}
														>
															{t('zam.list.kat')}{` `}
															{sort.col === 'Kat' && sort.ico}
														</th> 
													:
														""
													}
													{config?.Nazw3[5] === 'T' ? <th className="text-start vcentr" ids="Gr">{t('zam.list.grp')}</th> :""}
													{/* (Screen == "sm" || Screen == "md")
													className={`${Screen == "xl" ? "custom-offcanvas" : ""}`} */}
													<th 
														//className={`text-start vcentr`+ (sort.col == 'Nazw' ? `${sortactive}` : ``)}
														className={class_sort('Nazw')}
														ids="Nazw" 
														onClick={ChgSort}
													>
														{t('zam.list.name')}{` `}
														{sort.col === 'Nazw' && sort.ico}
													</th>
													{config?.Nazw3[4] === 'T' ? <th className="text-start vcentr" ids="Pr">{t('zam.list.prod')}</th> :""}
													{config?.Nazw3[0] === 'T' ? <th className="text-center vcentr" ids="STAN">{t('zam.list.stan')}</th> :""}
													{/* <th className="text-center vcentr" ids="Cena_tow">Cena</th>  */}
													<th>&nbsp;</th>{/* Cart-icon */}
												</tr>
											</thead>
											<tbody>

											{tows.items.map((tow, i) => (
												<tr 
													key={tow.Kod + tow.ID + i} 
													idp={tow.ID} 
													onDoubleClick={() => DblClkTow(tow.ID)}
													className="tow-row"
												>
													{/* Image?  */}
													<td 
														className="text-center vcentr calias" 
														id={tow.ID}
														onClick={() => DblClkTow(tow.ID)}
													>
														<Image 
															src={
																tow?.photo ? 
																	process.env.PUBLIC_URL + '/assets/images/item.png'
																:
																	process.env.PUBLIC_URL + '/assets/images/no_item.png'
															}
															style={{ width: '40px', }}
															className={`thumbnail-image shadow opacity-` + (tow?.photo ? `` : `25`) }
															alt="Image" 
															thumbnail 
															fluid 
														/>
													</td>
													{/* Kod */}
													{config?.Nazw3[9] === 'T' ?
															<td className="text-start vcentr" id={tow.ID}>{tow.Kod}</td>
														:
															""
													}
													{/* Numer katalagowy */}
													{config?.Nazw3[10] === 'T' ?
															<td className="text-start vcentr">{tow.Kat}</td>
														:
															""
													}
													{/* Grupa towarow */}
													{config?.Nazw3[5] === 'T' ?
															<td className="text-start vcentr">{tow.Gr}</td>
														:
															""
													}
													{/* Nazwa */}
													<td className="text-start vcentr">{pllng ? tow.Nazw?.trim() : tow.Nazw2?.trim()}</td>
													{/* Producent */}
													{config?.Nazw3[4] === 'T' ?
															<td className="text-start vcentr">{tow.Pr}</td>
														:
															""
													}
													{/* Stan */}
													{config?.Nazw3[0] === 'T' && (
														<td className="text-center vcentr">
															<OverlayTrigger
																placement="bottom"
																overlay={
																	<Tooltip id={`tooltip-${i}`}>
																		{t('zam.stock.' + ((parseFloat(tow.STAN)>0 || tow.STAN === 'S') ? 'in' : 'out'))}
																	</Tooltip>
																}
															>
																<Badge
																	className={`text-wrap ${
																		parseFloat(tow.STAN) > 0 || tow.STAN === 'S' ? 'bg-primary-own' : 'bg-warning-own'
																	}`}
																	style={{ fontSize: '1em', padding: '0.3em 0.6em' }}
																>
																	{(tow.STAN !== undefined && tow.STAN !== null && tow.STAN > 0) ? tow.STAN : 'Z'}
																</Badge>
															</OverlayTrigger>
														</td>
													)}
													{/* Cena */}
													{/* <td className="text-center vcentr">{tow.Cena_tow}</td> */}
													{/* Icon ADD to cart */}
													<td className="text-center vcentr">
														<KoszykSvg 
															//color="#289140" 
															size={25} 
															className="ani-btn rounded calias" 
															id={tow.ID}
															onClick={() => DblClkTow(tow.ID)}/>
													</td>
												</tr>
											))}
											</tbody>
										</Table>
									:
										<Alert variant="danger" className="shadow fade in" /*dismissible onClose={() => setAlertShow(false)}*/	>
											<strong>{t("zam.list.no_items")}!</strong>
										</Alert>
									}
									<br />
									{/* Pagination */}
									{tows?.perpage ?
										<div className="d-flex justify-content-center">
											<PaginationControl
												className='justify-content-center'
												page={tows.page}
												between={4}
												total={tows.total}
												limit={tows.perpage}
												changePage={(page) => {
													setTows((prev_t) => ({
														...prev_t, 
														page: page
													}))
												}}
												ellipsis={1}
												//last={1}
											/>
										</div>
									:
										""
									}
								</Col>
							</Row>
						</Container>
					</>
				:
					<>
						<Container fluid className="justify-content-center" style={{display:'flex'}} >
							<br /> 
							<br />
							<Spinner animation="grow" variant="info" />  Loading data...
						</Container>
					</>
				}


			</Container>
		</>
	)
}




/**
 * Small cart
 * @param {*} param
 * @returns 
 */
export const SmallCart = ({items, config}) =>{
	const {t} = useTranslation();
	// let sum = 0;
	//items.forEach(el => sum += Number.parseFloat(el.qty));
	return(
		<div className="shop-cart shadow-lg rounded-4">
			<div className={items.length ? " " : "empty"} >
				{items.length ? 
					<>
						{/* {items.forEach(el => sum += Number.parseFloat(el.qty))} */}
						{items.map((el, i) => (
							<CartSmallItem key={el.ID + JSON.stringify(el.cechy) + el.Nazw} item={el} config={config} />
						))}
						{/* <p className="summa">Sum: {Math.round(sum)}</p> */}
					</>
				:
					<>
						<h3 className="">
							<i>{t("zam.cart.items_no")}</i><br />
						</h3>
						{t("zam.cart.add_item_no")}
					</>
					}
			</div>
		</div>
	)

}