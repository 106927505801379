export const release = true;

/* File of global variables */
export const v_release = '2.1201';
export const d_release = '26.12.2024';
export const isGlobalLogoff = '2.1000';

export const BACK_HOST = release ? 'https://bpanel.firmatec.pl/api/v1' : 'http://back.fpanel.localhost:8000/api/v1';

//Local storages
export const USERDATA_Local_Storage = "User_data";
export const USERCART_Local_Storage = "User_cart";
export const USERCONFIG_Local_Storage = "User_config";
export const FirmCONFIG_Local_Storage = "Firm_config";
export const FirmINFO_Local_Storage = "Firm_info";

//Session storages
export const UrlRedirAfterLogin = "redir";

// Define ID for custom item
export const custID = "CUSTOM.ZAMOW.FIRMATEC.PL";

// Define header and it's value for ZamOnline_v2
export const SERVICE_HDR = "F-service";
export const ZAM_HDR = "ZamOnline_v2";

export const PDF_PATH = "https://files.firmatec.pl/dokumenty/";

export const RecaptchaSiteKey = "6LcH0IkoAAAAAOehXevgJMiUBEMe7SfW8oTQSqDA"
//REACT_APP_SECRET_KEY = "6LcH0IkoAAAAABylyDEG8i1CJN5e0E-WMtxkepEE"